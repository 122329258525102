
import { observable, action } from "mobx";
import Entity from "../apis/index";
import { backUrl } from "../../config";
import { message } from "antd";
class Store {
  @observable pageView = 'login';
  @observable loading = false;
  @observable client_id = null;
  @observable redirect_uri = null;
  @observable state = null;
  @observable groupList = [
  ];
  @observable userData = null;

  // 获取session
  @action.bound
  fetchSession(cb, errCb) {
    Entity.session.fetch({
      sucCallback: (data) => {
        console.log('session suc', data)
        cb(data);
      },
      errCallback: (data) => {
        console.log('session err', data)
        errCb();
      }
    });
  }
  @action.bound
  loginWithGroup(item) {
    this.loading = true;
    localStorage.setItem('orgId', item.id);
    console.log('item', item)
    // 判断是否是第三方登录
    if (this.client_id) {
      this.checkAuthApp(item.id)
    } else {
      // 跳转到userCenter
      window.location.href = '/uc/pages/userCenter';
    }
    setTimeout(() => {
      this.loading = false;
    }, 3000)
  }

  // 发送短信验证码
  @action.bound
  sendSmsCode(phone) {
    this.loading = true;
    Entity.sms.fetch({
      mobile: phone,
      sucCallback: (data) => {
        console.log('sendSmsCode suc', data)
        this.loading = false;
      },
      errCallback: (data) => {
        console.log('sendSmsCode err', data)
        this.loading = false;
      }
    });
  }

  // 手机号登录
  @action.bound
  loginWithPhone(phone, smsCode, jumpSelectOrg, jumpCreateOrg) {
    this.loading = true;
    Entity.login.fetch({
      method: 'sms',
      username: phone,
      sms_code: smsCode,
      sucCallback: (data) => {
        console.log('loginWithPhone suc', data)
        this.setLocalLoginData(data);
        // 判断是否有组织 有组织则跳转到组织列表页面
        if (data.user.orgs.length > 0) {
          jumpSelectOrg();
        } else {
          jumpCreateOrg();
        }
        this.loading = false;
      },
      errCallback: (data) => {
        console.log('loginWithPhone err', data)
        this.loading = false;
      }
    });
  }
  // 用户名密码登录
  @action.bound
  loginWithUsername(username, password, cb?) {
    this.loading = true;
    // this.checkUsernameNotExist(username, 'username', () => {

    // })
    Entity.login.fetch({
      method: 'password',
      username: username,
      password: password,
      sucCallback: (data) => {
        console.log('loginWithUsername suc', data)
        this.setLocalLoginData(data);
        this.loading = false;
        cb && cb(data.user.orgs);
        // 判断是否有组织 有组织则跳转到组织列表页面
        // if (data.user.orgs.length > 0) {
        //   jumpSelectOrg();
        // } else {
        //   jumpCreateOrg();
        // }
      },
      errCallback: (data) => {
        console.log('loginWithUsername err', data)
        this.loading = false;
      }
    });

  }
  // 重置密码
  @action.bound
  resetPassword(phone, password, smsCode, cb?) {
    this.loading = true;
    Entity.reset.fetch({
      mobile: phone,
      password: password,
      sms_code: smsCode,
      sucCallback: (data) => {
        console.log('resetPassword suc', data)
        message.success("重置密码成功!");
        cb && cb();
        this.loading = false;
      },
      errCallback: (data) => {
        console.log('resetPassword err', data)
        this.loading = false;
      }
    });
  }
  // 注册用户
  @action.bound
  reg(username, mobile, password, smsCode, cb?) {
    this.loading = true;
    Entity.reg.fetch({
      username: username,
      mobile: mobile,
      password: password,
      sms_code: smsCode,
      sucCallback: (data) => {
        console.log('register suc', data)
        this.setLocalLoginData(data);
        cb && cb();
        this.loading = false;
      }
      ,
      errCallback: (data) => {
        console.log('register err', data)
        this.loading = false;
      }
    });
  }

  // - 校验第三方应用跳转时携带参数是否正确接口
  checkAuthAppParams(cb?) {
    this.loading = true;
    Entity.authAppParams.fetch({
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      sucCallback: (data) => {
        console.log('checkAuthAppParams suc', data)
        this.loading = false;
        cb && cb();
      },
      errCallback: (data) => {
        this.loading = false;
        console.log('展示报错视图')
      }
    });
  }

  // 判断用户是否有权限使用当前的第三方应用
  @action.bound
  checkAuthApp(org_id, errCb?) {
    this.loading = true;
    this.checkAuthAppParams(() => {
      Entity.authApp.fetch({
        org_id,
        client_id: this.client_id,
        sucCallback: (data) => {
          console.log('checkAuthApp', data);
          // axios 请求 backUrl
          console.log('跳回第三方应用', this.redirect_uri);
          // url去掉参数
          let url = this.redirect_uri.split('?')[0];

          const redirctUrl = backUrl + `/oauth/authorize?token=${localStorage.getItem('token')}&client_id=${this.client_id}&state=${Math.random().toString(36).substring(2)}&response_type=code&redirect_uri=${url}`;
          console.log('redirctUrl2', redirctUrl)
          window.location.href = redirctUrl;
          this.loading = false;
        }
        ,
        errCallback: (data) => {
          this.loading = false;
          errCb && errCb();
          console.log('展示报错视图')
        }
      });
    })

  }
  // 新增组织
  @action.bound
  addOrg(name, cb?) {
    this.loading = true;
    Entity.org.fetch({
      name,
      sucCallback: (data) => {
        console.log('addOrg suc', data);
        // 设置OrgId
        localStorage.setItem('orgId', data.id);
        // 判断是否是第三方登录
        if (this.client_id) {
          this.checkAuthApp(data.id)
        }
        cb && cb();
        this.loading = false;
      }
      ,
      errCallback: (data) => {
        this.loading = false;
      }
    });
  }


  // 检测用户名是否注册
  @action.bound
  checkUsernameExist(username, type, cb?) {
    this.loading = true;
    Entity.check.fetch({
      type,
      value: username,
      sucCallback: (data) => {
        console.log('checkUsername suc', data)
        if (data && data.exists) {
          if (type === 'username') {
            message.error('用户名已存在');
          } else {
            message.error('手机号已存在');
          }

        } else {
          cb && cb();
        }
        this.loading = false;
      }
      ,
      errCallback: (data) => {
        console.log('checkUsername err', data)
        // message.error('用户名不存在');
        this.loading = false;
      }
    });
  }
  // 检测用户名是否未注册
  @action.bound
  checkUsernameNotExist(username, type, cb?) {
    this.loading = true;
    Entity.check.fetch({
      type,
      value: username,
      sucCallback: (data) => {
        console.log('checkUsername suc', data)
        if (data && !data.exists) {
          if (type === 'username') {
            message.error('用户名不存在');
          } else {
            message.error('手机号不存在');
          }
        } else {
          cb && cb();
        }
        this.loading = false;
      }
      ,
      errCallback: (data) => {
        console.log('checkUsername err', data)
        // message.error('用户名不存在');
        this.loading = false;
      }
    });
  }
  // 设置本地登录数据
  @action.bound
  setLocalLoginData(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    this.userData = data.user;
    this.groupList = data.user?.orgs;
  }
  // 清空本地登录数据
  @action.bound
  clearLocalLoginData() {
    localStorage.removeItem('token');
    localStorage.removeItem('orgId');
    localStorage.removeItem('user');
  }


}

const entity = new Store();
export default entity;
