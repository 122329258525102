import * as React from 'react';
import { observer } from 'mobx-react';
import { Result, Button } from 'antd';
import './index.scss';

type PageNotFoundState = {};
type PageNotFoundProps = {};

@observer export default class PageNotFound extends React.Component<
  PageNotFoundProps,
  PageNotFoundState
> {
  state = {};
  componentDidMount() { }

  render() {
    return (
      <div className="PageNotFound">
        <Result
          status="404"
          title="页面不存在"
          className='not-found'
          subTitle="抱歉，您所访问的页面不存在"
          extra={<Button type="primary" onClick={() => {
            window.location.href = '/login';
          }}>返回登录页面</Button>}
        />
      </div>
    );
  }
}
