// @ts-nocheck
import { observable, flow, toJS } from 'mobx';
import { flatten } from 'ramda';
import * as pathToRegexp from 'path-to-regexp';
import http from './http';


const methods = ['get', 'post', 'put', 'head', 'delete', 'patch', 'options'];

const isHttpMethods = (method: string) => {
  if (typeof method !== 'string') return false;
  return methods.indexOf(method.toLowerCase()) >= 0;
};

// const debuggerOn = process.env.NODE_ENV !== `production`;
const debuggerOn = true;
// @ts-ignore
const fn = (...arg: any[]) => {
  // console.log('处理参数', arg);
  return function generate<T extends new (...args: any[]) => {}>(
    constructor: T
  ) {
    const nextConstructor = class extends constructor { };

    for (const configure of flatten(arg)) {
      const {
        name,
        init,
        showErrMsg,
        wrapRes,
        bindLoading,
        cusFetch,
        extraParams,
        showSucMsg,
        isForm
      } = configure;
      let method: any = null;
      let path;
      for (const key in configure) {
        if (isHttpMethods(key)) {
          method = key;
          path = configure[key];
        }
      }

      // 所有类型都生成对应的 data 和 loading
      nextConstructor.prototype[name] = observable({
        data:
          typeof init === 'function' ? init(nextConstructor.prototype) : init,
        loading: false
      });

      // 生成 set 方法
      nextConstructor.prototype[`set${name}`] = (data: any) => {
        nextConstructor.prototype[name].data = data;
        debuggerOn &&
          console.info(
            `%c [${name}][data] emit value: `,
            `color: #4CAF50; font-weight: bold`,
            toJS(data)
          );
      };
      nextConstructor.prototype[name].set = (data: any) => {
        nextConstructor.prototype[`set${name}`](data);
      };

      if (!method || !path) {
        if (cusFetch) {
          // 生成自定义 fetch 方法
          nextConstructor.prototype[name].fetch = (params: any = {}) => {
            return cusFetch({ params, entity: nextConstructor.prototype });
          };
        }
      } else {
        // 自动生成 fetch 类型
        const toPath = pathToRegexp.compile(path);
        nextConstructor.prototype[`${name}Request`] = flow(function* (
          requestParams: any,
          useData: boolean
        ) {
          // @ts-ignore
          this[name].loading = true;
          // @ts-ignore
          bindLoading && (this[bindLoading].loading = true);
          debuggerOn &&
            console.info(
              `%c [${name}][loading] emit value: true`,
              `color: #4CAF50; font-weight: bold`
            );

          let data: any;
          if (useData) {
            data = requestParams.data;
          } else {
            data = { ...requestParams };
            delete data.sucCallback;
            delete data.errCallback;
            delete data.extraParams;
          }

          let response;
          try {
            const options: any = {
              method,
              url: toPath(requestParams),
              showErrMsg,
              showSucMsg,
              isForm
            };
            if (method === 'get' || method === 'delete') {
              options.params = data;
            } else {
              extraParams && (options.params = extraParams);
              requestParams.extraParams &&
                (options.params = requestParams.extraParams);
              options.data = data;
            }

            response = yield http(options);
            // @ts-ignore
            this[name].loading = false;
            // @ts-ignore
            bindLoading && (this[bindLoading].loading = false);
            debuggerOn &&
              console.info(
                `%c [${name}][loading] emit value: false`,
                `color: #4CAF50; font-weight: bold`
              );

            if (response.success) {
              // @ts-ignore
              this[name].data = wrapRes
                ? wrapRes({
                  res: response.data,
                  params: requestParams,
                  // @ts-ignore
                  entity: this
                })
                : response.data;
              debuggerOn &&
                console.info(
                  `%c [${name}][data] emit value: `,
                  `color: #4CAF50; font-weight: bold`,
                  toJS(this[name].data)
                );
              requestParams.sucCallback &&
                requestParams.sucCallback(response.data, response.fullData);
            } else {
              debuggerOn &&
                console.info(
                  `%c [${name}][fetch] failed`,
                  `color: #F6625C; font-weight: bold`
                );
              requestParams.errCallback &&
                requestParams.errCallback(response.data);
            }
          } catch (e) {
            this[name].loading = false;
            bindLoading && (this[bindLoading].loading = false);
            requestParams.errCallback && requestParams.errCallback(e);
          }
        });
        nextConstructor.prototype[name].fetch = (data = {}, useData) => {
          nextConstructor.prototype[`${name}Request`](data, useData);
        };
      }
    }

    return nextConstructor;
  };
};
export default fn;