import PageNotFound from '../base/PageNotFound';
import Page from '../base/Page';
import Login from '../Login/pages';
/**
 *  处理框架的路由与项目的路由
 *  根据配置分别配入 /home 或 /pages 路由之下
 *  并根据所有的配置生成 /home 下初始化的模块
 */


const routeConfig = [
    { name: 'Page not found', component: Login, path: '/' },
    {
        path: '/',
        component: Page,
        exact: true, // 如果为true，则仅在路径与location.pathname完全匹配时才匹配。
        childRoutes: [
            {
                path: '/login',
                component: Login,
                exact: true, // 如果为true，则仅在路径与location.pathname完全匹配时才匹配。
            },
            {
                path: '/oauth/authorize',
                component: Login,
                exact: true, // 如果为true，则仅在路径与location.pathname完全匹配时才匹配。
            },
            {
                path: '/token/authorize',
                component: Login,
                exact: true, // 如果为true，则仅在路径与location.pathname完全匹配时才匹配。
            },
            { name: 'Page not found', component: PageNotFound, path: '*' },
        ].filter(
            (r: any) => r['component'] || (r['childRoutes'] && r['childRoutes'].length > 0)
        ),
    },
];

// 生产配置json，其中每个路由配置还只是string，需要处理
const handleIndexRoute = (route: any) => {
    // 处理子路由
    if (!route.childRoutes || !route.childRoutes.length) {
        return;
    }
    const indexRoute = route.childRoutes.find((child: any) => child.isIndex);
    if (indexRoute) {
        const first = { ...indexRoute };
        first.path = route.path;
        first.exact = true;
        route.childRoutes.unshift(first); // 在对应路由下的子路由前端添加
    }
    route.childRoutes.forEach(handleIndexRoute); // 继续向内检索
};


export const moduleMenus = [];
// console.log('moduleMenuList', moduleMenuList);
// handleModuleMenuList(null, null, cloneDeep(moduleMenuList), moduleMenus);
// console.log('moduleMenus', moduleMenus);

routeConfig.forEach(handleIndexRoute); // 遍历每一项路由看看有没有子路由
export default routeConfig; // 将完整的路由表暴露出去
