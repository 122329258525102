// @ts-nocheck
import generate from '../../common/entityGenerator';
const configs = require('./api.js')

const newJson = (attr, value) => {
  const result = {};
  result[attr] = value;
  return result;
}

const finalConfig = configs.map(item =>
  Object.assign(
    {},
    { name: item.name, },
    newJson(item.method, '/apis' + item.apiPath))
)
// console.log('finalConfig', finalConfig);

@generate(finalConfig)
class Entity { finalConfig }

const entity: any = new Entity();
export default entity;
