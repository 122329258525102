import React from 'react';
// import ReactDOM from 'react-dom/client';
import { render } from 'react-dom';

import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import Routes from './routes';
import 'antd/dist/antd.min.css';
import './index.css';
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <ConfigProvider locale={zhCN}>
//       <Provider >
//         <Routes />
//       </Provider>
//     </ConfigProvider>
//   </React.StrictMode>
// );

const renderApp = () => render(

  <ConfigProvider locale={zhCN}>
    <Provider >
      <Routes />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);
renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
