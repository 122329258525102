// @ts-nocheck
import axios from 'axios';
import { jump } from '../common/utils/history';
import * as qs from 'qs';
import { backUrl } from '../config';
import { notification } from 'antd';

const showWarn = (message = '操作失败，请重试') => {
  notification.error({ message: '操作提示', description: message });
};

const showSuc = (message = '操作成功') => {
  notification.success({ message: '操作提示', description: message });
};

const errorHandle = (resp, showErrMsg, errCallback, url, method) => {
  console.log('errorHandle resp', resp);
  try {
    if (!resp || resp.status === 500 || (resp.data && resp.data.errCode === 500)) {
      if (resp && resp.data && resp.data.data && resp.data.data.code === 'unauthenticated') {
        showErrMsg && showWarn('登录过期啦');
        jump('/login');
        return;
      }
      if (resp.data && resp.data.message) {
        showErrMsg && showWarn(resp.data.message);
        return;
      }
      showErrMsg && showWarn(resp.data && resp.data.errMsg ? resp.data.errMsg : '服务器出现未知错误, 请联系管理员!');
      return;
    }
    if (resp.status === 504 || resp.status === 404 || resp.status === 405) {
      showErrMsg && showWarn('请求地址不存在 ⊙﹏⊙ !');
    } else if (resp.status === 422) {
      if (resp.data && resp.data.data) {
        showErrMsg && showWarn(JSON.stringify(resp.data.data && resp.data.data.length ? resp.data.data.map(item => item.content).join(',') : resp.data.data.data));
        return;
      }
      if (resp.data && resp.data.status && resp.data.status.data) {
        showErrMsg && resp.data.status.data ? showWarn(resp.data.status.errMsg + ":" + JSON.stringify(resp.data.status.data && resp.data.status.data.length ? resp.data.status.data.map(item => item.content).join(',') : resp.data.status.data)) : showWarn(resp.data.status.errMsg);
        return;
      }
      if (resp.data && resp.data.message) {
        showErrMsg && showWarn(resp.data.message);
        return;
      }

    } else if (resp.status === 403) {
      showErrMsg && showWarn('权限不足,请联系管理员！');
    } else if (resp.status === 401) {
      showErrMsg && showWarn(resp.data.status.errMsg || '登录过期啦');
      jump('/login');
    } else if (resp.data && resp.data.message) {
      showErrMsg && showWarn(resp.data.message);
    } else if (resp.message) {
      showErrMsg && showWarn(resp.message);
    }
    else {
      showErrMsg && showWarn(resp?.data?.message);
    }
    return;
  } catch (error) {
    console.log('error', error)
    showErrMsg && showWarn(resp?.data?.message);
  }

  // SentryOpen && Sentry.captureException(formatErrMsg(url, method, resp));
  errCallback && errCallback(resp.data);
};
export interface IParams {
  url: string;
  method: 'get', 'post', 'put', 'head', 'delete', 'patch', 'options';
  params?: any;
  paramsSerializer?: (params: any) => string;
  data?: any;
  headers?: any;
  wholeResp?: boolean;
  sucCallback?: (data: any, full?: any) => any;
  errCallback?: (err: any) => any;
  showSucMsg?: boolean;
  showErrMsg?: boolean;
  errTitle?: string;
  defaultValue?: any;
  debug?: boolean;
  responseType?: string;
  isForm?: boolean;
}

const success = ['background: green', 'color: white', 'display: block', 'text-align: center'].join(';');

const failure = ['background: red', 'color: white', 'display: block', 'text-align: center'].join(';');
let lastRequest = {
  url: '',
  method: '',
  data: null,
  params: null
};
const fn = ({
  url,
  method,
  data,
  params,
  paramsSerializer,
  wholeResp = false,
  sucCallback,
  errCallback,
  showErrMsg = true,
  showSucMsg = false,
  defaultValue,
  debug = false,
  responseType = 'json',
  isForm = false
}: IParams) =>
  new Promise<{ success: boolean; data: any, fullData?: any }>((resolve, reject) => {
    // console.log('url', url);
    let canRequest = true;
    // 判断正在请求的是否是同一个接口 排除登陆
    if (lastRequest.url === url && lastRequest.method === method && lastRequest.data === data && lastRequest.params === params && lastRequest.url !== '/apis/login') {
      console.log('相同接口', lastRequest, data, params);
      canRequest = false;
      return;
    } else {
      lastRequest.url = url;
      lastRequest.method = method;
      lastRequest.data = data;
      lastRequest.params = params;
    }
    !canRequest && console.log('取消请求');
    const options = {
      url: backUrl ? backUrl + url.replace('/apis', '') : url,
      method,
      params: {
        ...params
      },
      headers: {},
      paramsSerializer,
      data,
      responseType
    };
    isForm && (options['content-type'] = 'application/x-www-form-urlencoded');
    isForm && (options.data = qs.stringify(options.data));
    options.headers = {
      'org-id': localStorage.getItem('orgId'),
      'Authorization': localStorage.getItem('token')
    };
    // @ts-ignore
    axios(options)
      .then((resp: any) => {
        // console.log('resp', resp);
        if (!resp) {
          showErrMsg && showWarn('请求地址不存在 ⊙﹏⊙ !');
          return;
        }
        if (resp.status && resp.status === 200 && resp.data && (resp.data.status && +resp.data.status !== 0)) {
          debug && console.info(`%c ${method} ${url} failed!`, failure);
          showErrMsg && showWarn(resp.data.message);
          errCallback && errCallback(resp.data);
          resolve({
            success: false,
            data: defaultValue
          });
        } else {
          debug && console.info(`%c ${method} ${url} successful!`, success);
          showSucMsg && showSuc();
          sucCallback && sucCallback(wholeResp ? resp.data : resp.data ? resp.data.data : null, resp);
          resolve({
            success: true,
            data: wholeResp ? resp.data : resp.data ? resp.data.data : null,
            fullData: resp.data
          });
        }
        debug && console.info(`${method} ${url} response`, resp.data);
        lastRequest = {
          url: '',
          method: '',
          data: null,
          params: null
        };
      })
      .catch((err) => {
        setTimeout(() => {
          lastRequest = {
            url: '',
            method: '',
            data: null,
            params: null
          };
        }, 1000);
        console.log('error.response', err.response);
        debug && console.info(`%c ${method} ${url} failed!`, failure);
        debug && console.info(`${method} ${url} response`, err);
        if (err) {
          errorHandle(err.response, showErrMsg, errorHandle, url, method);
        } else {
          showErrMsg && showWarn(JSON.stringify(err));
        }
        // errCallback && errCallback(err);
        resolve({
          success: false,
          data: defaultValue
        });
      });
  });
export default fn;