import * as React from 'react';
import './index.scss';

type PageState = {};
type PageProps = {
    children?: any;
};

export default class Page extends React.Component<PageProps, PageState> {
    state = {};
    componentDidMount() {
    }

    render() {
        return (
            <div className="page-container">
                {this.props.children}
            </div>
        );
    }
}