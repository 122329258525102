import * as React from "react";
import LoginStore from "../store/login";
import { Input, Image, Checkbox, message, Result, Button } from "antd";
import { observer } from "mobx-react";
import {
  CloseCircleOutlined,
  LeftOutlined,
  SyncOutlined,
  MobileOutlined,
  GoldTwoTone,
  ArrowRightOutlined
} from "@ant-design/icons";
import isMobile from 'ismobilejs';
import QRCode from "react-qr-code";
import UnAuth from "./unAuth";
import "./index.scss";
type LoginState = {
  state: string;
  lastState: string;
  phone: string;
  username: string;
  password: string;
  isMobile: boolean;
  isValidPhone: boolean;
  isAutoLogin: boolean;
  smsState: string;
  currentSmsCodeIndex: number;
  smsCode: any;
  agreeDoc: boolean;
  retrySms: boolean;
  loginView: string;
  smsInterval: number;
  smsMaxInterval: number;
  orgName: string;
};
// const QrcodePng = require('../static/imgs/qrcode.png');
// const PcPng = require('../static/imgs/pc.png');
const ComputerPng = require('../static/imgs/computer.png');
const BackPng = require('../static/imgs/back.png');

type LoginProps = {
  history?: any
};
@observer
export default class Login extends React.Component<LoginProps, LoginState> {
  state = {
    state: 'normalLogin',

    lastState: 'normalLogin',
    loginView: 'phone',
    // "normalLogin",
    phone: "",
    username: "",
    isMobile: false,
    isValidPhone: false,
    isAutoLogin: false,
    password: "",
    currentSmsCodeIndex: 0,
    // smsLogin, smsRegister, smsForget, smsReset
    smsState: '',
    smsCode: ['', '', '', '', '', ''],
    agreeDoc: false,
    retrySms: false,
    smsInterval: 60,
    smsMaxInterval: 60,
    orgName: ''
  };
  smsIntervalId: any;
  componentDidMount() {
    this.checkMobile();
    // 监控window
    window.addEventListener("resize", this.handleResize);
    // 读取cookie中 autoLogin
    const autoLogin = localStorage.getItem("autoLogin");
    if (autoLogin) {
      this.setState({
        isAutoLogin: true
      });
    }


    const route = this.props.history.location.pathname;
    // 第三方登录
    if (route.indexOf('oauth/authorize') > -1) {
      //  从this.props.history.location.search中获取redirect_uri
      const redirect_uri = this.props.history.location.search.split('redirect_uri=')[1].split('&')[0];
      //  将redirect_uri存入store中
      LoginStore.redirect_uri = redirect_uri;
      //  从this.props.history.location.search中获取client_id
      const client_id = this.props.history.location.search.split('client_id=')[1].split('&')[0];
      //  将client_id存入store中
      LoginStore.client_id = client_id;
      // state
      const state = this.props.history.location.search.split('state=')[1].split('&')[0];
      // 将state存入store中
      LoginStore.state = state;
      // 输出
      console.log("redirect_uri", redirect_uri);
      console.log("client_id", client_id);
      console.log('state', state)
    }
    // 纯token登录
    if (route.indexOf('token/authorize') > -1) {
      LoginStore.clearLocalLoginData();
      console.log('携带token登录')
      const token = this.props.history.location.search.split('token=')[1].split('&')[0];
      console.log('token为：', token)
      localStorage.setItem('token', token);

    }


    // 判断当前是否登录过
    const isLogin = localStorage.getItem("token");
    if (isLogin) {
      // 查看token是否过期
      LoginStore.fetchSession(
        (data) => {
          console.log('选择组织', data)
          localStorage.setItem('user', JSON.stringify(data));
          this.checkOrg(data.orgs);
        }, () => {
          this.setState({
            state: 'normalLogin'
          })
          // LoginStore.clearLocalLoginData();
        }
      )

    } else {
      // 未登录 清空本地缓存
      LoginStore.clearLocalLoginData();
      this.setState({
        state: 'normalLogin'
      })
    }
  }
  getParamsFromRoute = name => {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  }
  checkOrg = (orgList) => {
    console.log('checkOrg', orgList, 'LoginStore.client_id', LoginStore.client_id)
    // 如果无组织
    if (orgList.length === 0) {
      // 跳转到组织创建视图
      this.setState({
        state: 'createOrg'
      })
      return;
    }
    // 如果组织数量为1个
    if (orgList.length === 1) {
      // 直接提取组织id
      const orgId = orgList[0].id;
      // 将组织id存入store中
      localStorage.setItem('orgId', orgId);
      // 判断是否是第三方登录
      if (LoginStore.client_id) {
        LoginStore.checkAuthApp(orgId)
      } else {
        console.log('非第三方组织')
        window.location.href = '/uc/pages/userCenter';
      }
      return;
    } else {
      // 当组织多个时 不应该基于之前的选择直接跳转（目前是直接跳转），应该到组织选择列表界面，让用户重新选择组织进行登录以及跳转。
      // 判断当前是否有选择过组织
      // const orgId = localStorage.getItem('orgId');
      // if (orgId) {
      //   // 判断是否是第三方登录
      //   if (LoginStore.client_id) {
      //     LoginStore.checkAuthApp(orgId)
      //   } else {
      //     console.log('非第三方组织')
      //     window.location.href = '/uc/pages/userCenter';
      //   }
      //   return;
      // }
    }
    if (LoginStore.client_id) {
      // 组织列表需基于当前登录的应用，屏蔽无权使用该应用的组织
      //  orgList 中 oauth_clients中存在id 为 LoginStore.client_id
      LoginStore.groupList = orgList.filter(item => {
        return item.oauth_clients.some(client => {
          return client.id === LoginStore.client_id
        })
      })

    } else {
      LoginStore.groupList = orgList;
    }

    // 跳到选择组织视图
    this.setState({
      state: 'selectOrg'
    })
  }
  handleResize = () => {
    const that = this;
    console.log('resize')
    that.checkMobile();
  }
  checkMobile() {
    if (isMobile().any) {
      this.setState({
        isMobile: true
      })
    } else {
      this.setState({
        isMobile: false
      })
    }
  }
  checkMobileValid = () => {
    // 校验手机号格式
    const { phone } = this.state;
    if (phone.length === 11 && /^1[3|4|5|7|8][0-9]\d{8}$/.test(phone)) {
      this.setState({
        isValidPhone: true,
        smsState: 'smsLogin',
        lastState: 'normalLogin',
        state: 'verifyPhone'
      });
      this.startSendSms();
      console.log('请求登录')
    } else {
      this.setState({
        isValidPhone: false
      });
      message.error("请输入正确的手机号");
    }
  }
  checkUsername = () => {
    if (!this.state.username) {
      message.error("请输入用户名");
      return;
    } else {
      LoginStore.loginWithUsername(this.state.username, this.state.password,
        (orgList) => {
          this.checkOrg(orgList);
        }
      )
    }

  }
  changeFieldValue = (type: string, value: string) => {
    console.log('changeFieldValue', type, value)
    // @ts-ignore
    this.setState({
      [type]: value
    });
  }
  clearField = (name: string) => {
    // @ts-ignore
    this.setState({
      [name]: ""
    });
  }
  autoLogin = () => {
    this.setState({
      isAutoLogin: !this.state.isAutoLogin
    })
    // 在cookie中设置autoLogin键值为true
    // @ts-ignore
    localStorage.setItem("autoLogin", this.state.isAutoLogin);
  }
  changeSmsCode = (e: any) => {
    // console.log('e', e, e.target.value)
    // console.log(' this.state.smsCode', this.state.smsCode)
    if (e.target.value) {
      let temp = this.state.smsCode;
      // 只获取最后一位
      temp[this.state.currentSmsCodeIndex] = e.target.value.substr(e.target.value.length - 1);
      this.setState({
        smsCode: temp,
        currentSmsCodeIndex: this.state.currentSmsCodeIndex + 1 <= 6 ? this.state.currentSmsCodeIndex + 1 : 6
      }, () => {
        console.log('this.state.smsCode', this.state.smsCode)
        if (this.state.currentSmsCodeIndex <= 6) {
          temp[this.state.currentSmsCodeIndex] = " ";
          this.setState({
            smsCode: temp,
          })
        }

        if (this.state.currentSmsCodeIndex === 6) {
          console.log('验证' + this.state.smsCode.join(""))
          // 如果验证码过期
          // if (this.state.retrySms) {
          //   message.info('验证码已过期，请重新获取');
          //   this.setState({
          //     smsCode: ["", "", "", "", "", ""],
          //     currentSmsCodeIndex: 0
          //   })
          //   return;
          // }


          // 判断验证码
          if (this.state.smsCode.join("").trim().length === 6) {
            if (this.state.lastState === 'forgetPass') {
              console.log('重置密码', this.state.lastState)
              LoginStore.resetPassword(this.state.phone, this.state.password, this.state.smsCode.join("").trim(), () => {
                this.setState({
                  state: 'normalLogin',
                  loginView: 'pass',
                  lastState: 'forgetPass',
                  phone: "",
                  password: "",
                  username: ""
                })
              });
            }
            if (this.state.lastState === 'reg') {
              console.log('直接注册并帮他登录了', this.state.lastState)
              LoginStore.reg(this.state.username, this.state.phone, this.state.password, this.state.smsCode.join("").trim());
            }
            if (this.state.lastState === 'normalLogin') {
              console.log('直接登录', this.state.lastState)
              LoginStore.loginWithPhone(this.state.phone, this.state.smsCode.join("").trim(),
                () => {
                  this.setState({
                    state: 'selectOrg'
                  });
                }, () => {
                  this.setState({
                    state: 'createOrg'
                  });
                });
            }
          } else {
            message.error("验证码错误");
            this.setState({
              smsCode: ["", "", "", "", "", ""],
              currentSmsCodeIndex: 0
            })
          }
          this.setState({
            smsCode: ["", "", "", "", "", ""],
            currentSmsCodeIndex: 0
          })
        }
      })
    } else {
      let temp = this.state.smsCode;
      temp[this.state.currentSmsCodeIndex] = '';
      this.setState({
        smsCode: temp,
        currentSmsCodeIndex: this.state.currentSmsCodeIndex - 1 > 0 ? this.state.currentSmsCodeIndex - 1 : 0
      })
    }
  }
  startSendSms = () => {
    this.setState({
      smsInterval: this.state.smsMaxInterval,
      retrySms: false
    }, () => {
      LoginStore.sendSmsCode(this.state.phone)
      this.startCountDown();
    })
  }
  // 开始倒计时
  startCountDown = () => {
    const that = this;
    if (that.smsIntervalId) {
      clearInterval(that.smsIntervalId)
    }
    that.smsIntervalId = setInterval(() => {
      that.setState({
        smsInterval: that.state.smsInterval - 1
      })
      if (that.state.smsInterval === 0) {
        clearInterval(that.smsIntervalId);
        that.setState({
          retrySms: true
        })
      }
    }, 1000)
  }
  // 重置倒计时
  resetCountDown = () => {
    clearInterval(this.smsIntervalId);
    this.setState({
      smsInterval: this.state.smsMaxInterval,
      retrySms: false
    })
  }
  componentWillUnmount(): void {
    clearInterval(this.smsIntervalId)
  }

  render() {
    return (
      <div className="login_container">

        <div className={this.state.isMobile ? "page-wrapper app-page-fullview app-page-boxbg-mob page-wrapper-visible" : "page-wrapper app-page-bannerview app-page-boxbg-pc page-wrapper-visible"}>

          <div className="banner-area" style={{ backgroundImage: `url(${BackPng})` }}>

            <div className="banner-link">

            </div>

          </div>

          <div className="app-area">
            {
              LoginStore.pageView === 'login' ?
                <div className="app-box">
                  <div className="app-box-content">

                    <div className={`app-loading ${LoginStore.loading ? 'app-loading-show' : ''}`}>
                      <div className="app-loading-bar">
                      </div>
                    </div>

                    <div className={`app-page ${this.state.state === 'setPassword' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-content">
                        <div className="module-change-password">
                          <div className="module-comm-page-title">{this.state.lastState === 'reg' ? '请设置用户信息' : '请设置密码'}
                          </div>
                          <div className="module-comm-page-desc">
                          </div>
                          {
                            this.state.lastState === 'reg' ?
                              <div className="module-pass-login-input-box base-comp-mobile-box base-comp-input-box">
                                <div className="base-comp-mobile-box-input">
                                  <Input value={this.state.username} placeholder="请输入账号" onChange={e => { this.changeFieldValue('username', e.target.value) }} />
                                </div>
                                {
                                  this.state.username ?
                                    <div className="base-comp-mobile-box-op dingtalk-login-iconfont dingtalk-login-icon-clear-cle" onClick={() => {
                                      this.clearField('username')
                                    }} >
                                      <CloseCircleOutlined size={48} style={{ fontSize: 18 }} />
                                    </div> : null
                                }
                              </div> : null
                          }

                          <div className="module-change-password-input-box base-comp-password-box  base-comp-input-box">

                            <div className="base-comp-password-box-input">
                              <Input.Password bordered={false} placeholder="请输入密码" value={this.state.password} onChange={e => { this.changeFieldValue('password', e.target.value) }} />
                            </div>
                            <div className="base-comp-password-box-op dingtalk-login-iconfont dingtalk-login-icon-visible-h">
                            </div>
                          </div>
                          {
                            this.state.lastState === 'reg' ? <div className={`base-comp-button base-comp-button-type-primary ${this.state.password && this.state.username ? '' : 'base-comp-button-disabled'}`}
                              onClick={() => {
                                LoginStore.checkUsernameExist(this.state.username, 'username', () => {
                                  this.resetCountDown();
                                  this.startSendSms();
                                  console.log('注册账号')
                                  this.setState({
                                    state: 'verifyPhone',
                                    lastState: 'reg'
                                  })
                                })
                              }}
                            >确认
                            </div> : null
                          }
                          {
                            this.state.lastState === 'forgetPass' ? <div className={`base-comp-button base-comp-button-type-primary ${this.state.password ? '' : 'base-comp-button-disabled'}`}
                              onClick={() => {
                                console.log('忘记密码')
                                this.setState({
                                  state: 'normalLogin',
                                  loginView: 'pass',
                                  lastState: 'setPassword'
                                })

                              }}
                            >确认
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    <div className={`app-page ${this.state.state === 'selectOrg' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-scroll">
                        <div className="app-scroll-content">
                          <div className="app-content">
                            <div className="module-corp-sel">
                              <div className="module-comm-page-back-btn" onClick={() => {
                                this.setState({
                                  lastState: 'selectOrg',
                                  state: 'normalLogin'
                                })
                              }}>
                                <LeftOutlined /> 返回</div>
                              <div className="module-comm-page-title">
                                选择你所在的组织
                              </div>
                              <div className="module-comm-page-desc">从以下组织中选择</div>
                              <div className="module-corp-sel-list">
                                {
                                  LoginStore.groupList.map(item =>

                                    <div className="module-corp-sel-listitem" onClick={() => { LoginStore.loginWithGroup(item) }}>
                                      <div className="module-corp-sel-listitem-avatar">
                                        <div className="module-corp-sel-listitem-icon dingtalk-login-iconfont dingtalk-login-icon-team">
                                          <GoldTwoTone />
                                        </div>
                                      </div>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td className="module-corp-sel-listitem-title">{item.name}</td>
                                          </tr>

                                        </tbody>
                                      </table>
                                      <div className="module-corp-sel-listitem-arrow dingtalk-login-iconfont dingtalk-login-icon-goto">
                                        <ArrowRightOutlined />
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className={`app-page ${this.state.state === 'createOrg' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-scroll">
                        <div className="app-scroll-content">
                          <div className="app-content">
                            <div className="module-corp-sel">
                              <div className="module-comm-page-back-btn" onClick={() => {
                                this.setState({
                                  lastState: 'createOrg',
                                  state: 'normalLogin'
                                })
                              }}>
                                <LeftOutlined /> 返回</div>
                              <div className="module-comm-page-title">
                                创建组织
                              </div>
                              <div className="module-pass-login-input-box base-comp-mobile-box base-comp-input-box">
                                <div className="base-comp-mobile-box-input">
                                  <Input bordered={false} placeholder="请输入组织名" value={this.state.orgName} onChange={e => { this.changeFieldValue('orgName', e.target.value) }}
                                    // 监听回车
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        LoginStore.addOrg(this.state.orgName)
                                      }
                                    }
                                    }
                                  />
                                </div>
                                {
                                  this.state.orgName ?
                                    <div className="base-comp-mobile-box-op dingtalk-login-iconfont dingtalk-login-icon-clear-cle" onClick={() => {
                                      this.clearField('orgName')
                                    }} >
                                      <CloseCircleOutlined size={48} style={{ fontSize: 18 }} />
                                    </div> : null
                                }
                              </div>
                              <div style={{ marginTop: '20px' }} className={`base-comp-button base-comp-button-type-primary ${this.state.orgName ? '' : 'base-comp-button-disabled'}`}
                                onClick={() => {
                                  LoginStore.addOrg(this.state.orgName)
                                }}
                              >确认
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className={`app-page ${this.state.state === 'forgetPass' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-content">
                        <div className="module-forget-password">
                          <div className="module-comm-page-back-btn" onClick={() => {
                            this.setState({
                              lastState: 'forgetPass',
                              state: 'normalLogin'
                            })
                          }}>
                            <LeftOutlined /> 返回</div>
                          <div className="module-comm-page-title">重置帐号密码</div>
                          <div className="module-forget-password-input-box base-comp-mobile-box base-comp-input-box">
                            <div className="base-comp-mobile-box-input">
                              <Input placeholder="请输入手机号" value={this.state.phone} onChange={(e) => this.changeFieldValue('phone', e.target.value)} />
                            </div>
                            {
                              this.state.phone ?
                                <div className="base-comp-mobile-box-op dingtalk-login-iconfont dingtalk-login-icon-clear-cle" onClick={() => {
                                  this.clearField('phone')
                                  this.setState({
                                    isValidPhone: false
                                  })
                                }} >
                                  <CloseCircleOutlined size={48} style={{ fontSize: 18 }} />
                                </div> : null
                            }
                          </div>
                          <div className="module-forget-password-input-box base-comp-mobile-box base-comp-input-box">
                            <div className="base-comp-password-box-input">
                              <Input.Password bordered={false} placeholder="请输入密码" value={this.state.password} onChange={e => { this.changeFieldValue('password', e.target.value) }} />
                            </div>
                            <div className="base-comp-password-box-op dingtalk-login-iconfont dingtalk-login-icon-visible-h">
                            </div>
                          </div>

                          <div className=" base-comp-button base-comp-button-type-primary " onClick={() => {
                            if (this.state.phone.length === 11 && /^1[3|4|5|7|8][0-9]\d{8}$/.test(this.state.phone)) {
                              LoginStore.checkUsernameNotExist(this.state.phone, 'mobile', () => {
                                this.setState({
                                  isValidPhone: true,
                                  lastState: 'forgetPass',
                                  state: 'verifyPhone'
                                }, () => {
                                  this.startSendSms();
                                })
                              })

                            } else {
                              this.setState({
                                isValidPhone: false
                              });
                              message.error("请输入正确的手机号");
                            }
                          }}>确认</div>
                        </div></div>
                    </div>


                    <div className={`app-page ${this.state.state === 'normalLogin' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-content">
                        <div className="module-pass-login">
                          <div className="module-pass-app-info">
                            <div className="module-pass-login-logo module-pass-login-logo-fill dingtalk-login-iconfont dingtalk-login-icon-dingtalk">
                            </div>
                            <div className="module-pass-login-title">登录九龙
                            </div>
                            <div className="module-pass-login-desc">继续使用九龙管理后台
                            </div>
                          </div>
                          <div className="module-pass-login-type-tab-area">
                          </div>
                          <div className={`module-pass-login-form-area module-pass-login-form-area-email ${this.state.loginView === 'pass' ? 'module-pass-login-form-area-active' : ''}`}>
                            <div className="module-pass-login-input-box base-comp-mobile-box base-comp-input-box">
                              <div className="base-comp-mobile-box-input">
                                <Input value={this.state.username} placeholder="请输入账号" onChange={e => { this.changeFieldValue('username', e.target.value) }} />
                              </div>
                              {
                                this.state.username ?
                                  <div className="base-comp-mobile-box-op dingtalk-login-iconfont dingtalk-login-icon-clear-cle" onClick={() => {
                                    this.clearField('username')
                                  }} >
                                    <CloseCircleOutlined size={48} style={{ fontSize: 18 }} />
                                  </div> : null
                              }
                            </div>
                            <div className={`module-pass-login-input-box base-comp-password-box base-comp-password-box-showforgot base-comp-input-box`}>

                              <div className="base-comp-password-box-input">
                                <Input.Password bordered={false} placeholder="请输入密码" value={this.state.password} onChange={e => { this.changeFieldValue('password', e.target.value) }} />
                              </div>
                              <div className="base-comp-password-box-forgot" onClick={() => {
                                this.setState({
                                  lastState: 'normalLogin',
                                  state: 'forgetPass'
                                })
                              }}>忘记密码
                              </div>
                            </div>
                            <div className={`base-comp-button base-comp-button-type-primary ${this.state.username && this.state.password ? '' : 'base-comp-button-disabled'}`} onClick={this.checkUsername}>下一步
                            </div>
                            <div className="module-pass-login-op-line">
                              <div className="base-comp-check-box">
                                <div className="base-comp-check-box module-pass-login-op-item-rememberme">
                                  <Checkbox checked={this.state.isAutoLogin} onChange={this.autoLogin}>自动登录</Checkbox>
                                </div>
                                {
                                  this.state.loginView === 'pass' && <div className="account-login-item" onClick={() => {
                                    this.changeFieldValue('loginView', 'phone')
                                  }}>
                                    手机号登录
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className={`module-pass-login-form-area module-pass-login-form-area-email ${this.state.loginView === 'phone' ? 'module-pass-login-form-area-active' : ''}`}>
                            <div className="module-pass-login-input-box base-comp-mobile-box base-comp-input-box">
                              <div className="base-comp-mobile-box-input">
                                <Input value={this.state.phone} placeholder="请输入手机号"
                                  // 监听回车
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      this.checkMobileValid();
                                    }
                                  }
                                  }
                                  onChange={(e) => { this.changeFieldValue('phone', e.target.value) }} />
                              </div>
                              {
                                this.state.phone ?
                                  <div className="base-comp-mobile-box-op dingtalk-login-iconfont dingtalk-login-icon-clear-cle"
                                    onClick={() => {
                                      this.clearField('phone')
                                      this.setState({
                                        isValidPhone: false
                                      })
                                    }} >
                                    <CloseCircleOutlined size={48} style={{ fontSize: 18 }} />
                                  </div> : null
                              }

                            </div>
                            <div className={`base-comp-button base-comp-button-type-primary ${this.state.phone ? '' : 'base-comp-button-disabled'}`} onClick={this.checkMobileValid}>下一步
                            </div>
                            <div className="module-pass-login-op-line">
                              <div className="base-comp-check-box module-pass-login-op-item-rememberme">
                                <Checkbox checked={this.state.isAutoLogin} onChange={this.autoLogin}>自动登录</Checkbox>
                              </div>
                              {this.state.loginView === 'phone' && <div className="account-login-item" onClick={() => {
                                this.changeFieldValue('loginView', 'pass')
                              }}>
                                帐号登录
                              </div>}


                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="module-footer">
                        <div className="module-footer-item" onClick={() => {
                          this.setState({
                            state: 'reg',
                            lastState: 'normalLogin'
                          })
                        }} >注册帐号
                        </div>

                      </div>
                    </div>
                    <div className={`app-page ${this.state.state === 'qrCodeLogin' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-page-bg">
                        <div className="app-page-bg-pc">
                        </div>
                        <div className="app-page-bg-mob">
                        </div>
                      </div>
                      <div className="app-content ">
                        <div className="module-qrcode ">
                          <div className="module-qrcode-login-logo module-qrcode-login-logo-fill dingtalk-login-iconfont dingtalk-login-icon-dingtalk">
                          </div>
                          <div className="module-qrcode-login-title">使用九龙扫码
                          </div>
                          <div className="module-qrcode-login-desc">继续使用九龙管理后台
                          </div>
                          <div className="module-qrcode-area module-qrcode-area-expired">
                            <div className="module-qrcode-code">
                              <QRCode value="http://baidu.com" size={200}></QRCode>
                              <div className="module-qrcode-expired-cover" onClick={() => {
                                console.log('刷新二维码')
                              }}>
                                {/* <span className="dingtalk-login-iconfont dingtalk-login-icon_refresh1"></span> */}
                                <SyncOutlined style={{ paddingTop: '2px' }} />

                              </div>
                            </div>
                            <div className="module-qrcode-op-line">
                              <div className="base-comp-check-box module-qrcode-op-item ">
                                {/* <div className="base-comp-check-box-rememberme-box dingtalk-login-iconfont dingtalk-login-icon-checkbox-undone">
                            </div>自动登录 */}
                                <div className="base-comp-check-box module-pass-login-op-item-rememberme">
                                  <Checkbox checked={this.state.isAutoLogin} onChange={this.autoLogin}>自动登录</Checkbox>
                                </div>
                              </div>
                            </div>
                            <div className="module-qrcode-expired-tip">二维码已失效，点击刷新
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="module-footer">
                        <div className="module-footer-item">注册帐号
                        </div>

                      </div>
                    </div>
                    <div className={`app-page ${this.state.state === 'reg' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-content">
                        <div className="module-register">
                          <div className="module-comm-page-back-btn"
                            onClick={() => {
                              this.setState({
                                state: 'normalLogin',
                                lastState: 'reg'
                              })
                            }}
                          ><LeftOutlined />返回
                          </div>
                          <div className="module-comm-page-title">注册帐号
                          </div>
                          <div className="module-register-input-box base-comp-mobile-box base-comp-input-box">

                            <div className="base-comp-mobile-box-input">
                              <Input placeholder="请输入手机号" value={this.state.phone}
                                onChange={(e) => this.changeFieldValue('phone', e.target.value)}
                                // 监听回车
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    if (!this.state.agreeDoc) { return; }
                                    if (this.state.phone.length === 11 && /^1[3|4|5|7|8][0-9]\d{8}$/.test(this.state.phone)) {
                                      LoginStore.checkUsernameExist(this.state.phone, 'mobile', () => {
                                        this.setState({
                                          isValidPhone: true,
                                          smsState: 'smsRegister',
                                          lastState: 'reg',
                                          state: 'setPassword'
                                        });
                                        this.startSendSms();
                                      })

                                    } else {
                                      this.setState({
                                        isValidPhone: false
                                      });
                                      message.error("请输入正确的手机号");
                                    }
                                  }
                                }
                                }
                              />

                            </div>
                            {
                              this.state.phone ?
                                <div className="base-comp-mobile-box-op dingtalk-login-iconfont dingtalk-login-icon-clear-cle"
                                  onClick={() => {
                                    this.clearField('phone')
                                    this.setState({
                                      isValidPhone: false
                                    })
                                  }} >
                                  <CloseCircleOutlined size={48} style={{ fontSize: 18 }} />
                                </div> : null
                            }
                          </div>
                          <div className={`base-comp-button base-comp-button-type-primary ${this.state.agreeDoc && this.state.phone ? '' : 'base-comp-button-disabled'}`}
                            onClick={() => {
                              if (!this.state.agreeDoc) { return; }
                              if (this.state.phone.length === 11 && /^1[3|4|5|7|8][0-9]\d{8}$/.test(this.state.phone)) {
                                LoginStore.checkUsernameExist(this.state.phone, 'mobile', () => {
                                  this.setState({
                                    isValidPhone: true,
                                    smsState: 'smsRegister',
                                    lastState: 'reg',
                                    state: 'setPassword'
                                  });
                                  this.startSendSms();
                                })

                              } else {
                                this.setState({
                                  isValidPhone: false
                                });
                                message.error("请输入正确的手机号");
                              }
                            }}
                          >确认

                          </div>
                          <div className="module-register-op-line">
                            <div className="module-register-op-item-agree-box dingtalk-login-iconfont dingtalk-login-icon-checkbox-undone">
                            </div>
                            <div className="module-register-op-item">
                              <span><Checkbox checked={this.state.agreeDoc} onChange={() => {
                                this.setState({
                                  agreeDoc: !this.state.agreeDoc
                                })
                              }}>
                                我已阅读并同意
                              </Checkbox></span><span className="module-register-op-item-link">服务协议</span><span className="module-register-op-item-link">隐私政策</span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`app-page ${this.state.state === 'verifyPhone' ? 'app-page-curr' : 'app-page-next'}`}>
                      <div className="app-content">
                        <div className="module-verify">
                          <div className="module-comm-page-back-btn" onClick={() => {
                            this.setState({
                              // lastState: 'verifyPhone',
                              state: this.state.lastState
                            })
                          }}><LeftOutlined />返回</div>
                          <div className="module-comm-page-title">验证手机号
                          </div>
                          <div className="module-comm-page-desc">已向{this.state.phone}发送验证码，请查收并输入验证码。 <span>收不到验证码？</span>
                          </div>
                          <div className="module-verify-code-area">
                            <div className="module-verify-code-container">
                              <div className="module-verify-code-box">
                                {
                                  this.state.currentSmsCodeIndex === 0 ? <Input autoFocus maxLength={2} bordered={false} className="module-verify-code-input" value={this.state.smsCode[0]} onChange={this.changeSmsCode} /> : this.state.smsCode[0]
                                }
                              </div>
                            </div>
                            <div className="module-verify-code-container">
                              <div className="module-verify-code-box">
                                {
                                  this.state.currentSmsCodeIndex === 1 ? <Input autoFocus maxLength={2} bordered={false} className="module-verify-code-input" value={this.state.smsCode[1]} onChange={this.changeSmsCode} /> : this.state.smsCode[1]
                                }
                              </div>
                            </div>
                            <div className="module-verify-code-container">
                              <div className="module-verify-code-box">
                                {
                                  this.state.currentSmsCodeIndex === 2 ? <Input autoFocus maxLength={2} bordered={false} className="module-verify-code-input" value={this.state.smsCode[2]} onChange={this.changeSmsCode} /> : this.state.smsCode[2]
                                }
                              </div>
                            </div>
                            <div className="module-verify-code-container">
                              <div className="module-verify-code-box">
                                {
                                  this.state.currentSmsCodeIndex === 3 ? <Input autoFocus maxLength={2} bordered={false} className="module-verify-code-input" value={this.state.smsCode[3]} onChange={this.changeSmsCode} /> : this.state.smsCode[3]
                                }
                              </div>
                            </div>
                            <div className="module-verify-code-container">
                              <div className="module-verify-code-box">
                                {
                                  this.state.currentSmsCodeIndex === 4 ? <Input autoFocus maxLength={2} bordered={false} className="module-verify-code-input" value={this.state.smsCode[4]} onChange={this.changeSmsCode} /> : this.state.smsCode[4]
                                }
                              </div>
                            </div>
                            <div className="module-verify-code-container">
                              <div className="module-verify-code-box">
                                {
                                  this.state.currentSmsCodeIndex === 5 ? <Input autoFocus maxLength={2} bordered={false} className="module-verify-code-input" value={this.state.smsCode[5]} onChange={this.changeSmsCode} /> : this.state.smsCode[5]
                                }
                              </div>
                            </div>
                          </div>
                          <div className="module-comm-page-desc">
                            {
                              !this.state.retrySms ? <div>00:{Number(this.state.smsInterval) < 10 ? '0' + this.state.smsInterval : this.state.smsInterval} 后 重发验证码 或 通过其他途径验证</div> : <div>还收不到验证码？请先确认手机是否安装了短信拦截软件或是否欠费停机。若均不是，请再尝试以下方式</div>
                            }
                            {
                              this.state.retrySms ? <div className="module-verify-op-btn" onClick={() => {
                                this.startSendSms();
                              }}><span className="dingtalk-login-iconfont dingtalk-login-icon-mobile"><MobileOutlined /></span>重发验证码</div> : null
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> : null
            }
            {
              LoginStore.pageView === 'appUnValid' ?
                <UnAuth />
                : null
            }

            <div className="app-cpright-bar">
              <div className="app-cpright">© 2014-2021 九龙公司 版权所有
              </div>
              <div className="app-privacy"><span>隐私政策</span><span>服务协议</span><span>法律声明</span>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
