import * as React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import history from './common/utils/history';
import routeConfig from './routes/index';

const renderRouteConfigV5 = (Container: JSX.IntrinsicAttributes | null, routes: any[], contextPath = '/') => {
    // Resolve route config object in React Router v3.
    const children: JSX.Element[] = []; // children component list

    const renderRoute = (item: { path: string; component: any; childRoutes: any[]; }, routeContextPath: string) => {
        let newContextPath: React.Key | null | undefined;
        if (/^\//.test(item.path)) {
            newContextPath = item.path;
        } else {
            newContextPath = `${routeContextPath}/${item.path}`;
        }
        newContextPath = newContextPath.replace(/\/+/g, '/');
        if (item.component && item.childRoutes) {
            children.push(renderRouteConfigV5(item.component, item.childRoutes, newContextPath));
        } else if (item.component) {
            // @ts-ignore
            children.push(<Route key={newContextPath} component={item.component} path={newContextPath} history={history} exact />);
        } else if (item.childRoutes) {
            // @ts-ignore
            item.childRoutes.forEach((r: any) => renderRoute(r, newContextPath));
        }
    };

    routes.forEach((item: any) => renderRoute(item, contextPath));
    // Use Switch as the default container by default
    if (!Container) return <Switch>{children}</Switch>;

    return (
        // @ts-ignore
        <Container key={contextPath}>
            <Switch>
                {children}
            </Switch>
        </Container>
    );
};

export default class Routes extends React.PureComponent<Props, States> {
    componentWillMount() {
        // console.log('加载动态路由数据');
    }
    render() {
        const children = renderRouteConfigV5(null, routeConfig, '/');
        // console.log('renderRouteConfigV5 children', children)
        return (
            <Router history={history}>
                <div key={history.location.key}>
                    {children}
                </div>
            </Router>
        );
    }
}

interface Props { }
interface States { }