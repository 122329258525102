module.exports = [
  {
    method: 'post',
    name: 'login',
    apiPath: '/login',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'get',
    name: 'session',
    apiPath: '/session',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'post',
    name: 'reset',
    apiPath: '/reset',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'post',
    name: 'reg',
    apiPath: '/register',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'post',
    name: 'check',
    apiPath: '/check',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'post',
    name: 'sms',
    apiPath: '/sms',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'post',
    name: 'org',
    apiPath: '/org',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'get',
    name: 'authAppParams',
    apiPath: '/oauth/preflight',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'post',
    name: 'authApp',
    apiPath: '/oauth/select',
    needRequestServer: true,
    requestAuthCheck: false
  },
  {
    method: 'get',
    name: 'speedQuery',
    apiPath: '/speedtest_query',
    needRequestServer: true,
    requestAuthCheck: false
  },
];