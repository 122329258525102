
import * as React from 'react';
import { observer } from 'mobx-react';
import LoginStore from "../store/login";
import './index.scss';
import { Button, Result } from 'antd';

type UnAuthState = {};
type UnAuthProps = {};

@observer
export default class UnAuth extends React.Component<UnAuthProps, UnAuthState> {
    state = {};
    componentDidMount() { }

    render() {
        return (
            <Result
                className="appUnValid"
                status="warning"
                title="无权限使用本应用，请联系组织组织管理员开通"
                extra={
                    <Button type="primary" key="console" onClick={() => {
                        LoginStore.pageView = 'login'
                    }}>
                        返回登录页面
                    </Button>
                }
            />
        );
    }
}